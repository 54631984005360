<template>
  <div>
    <router-view v-if="user.id" />
    <div v-else>
      <div v-if="!error">
        <b-skeleton-img height="500px" />
      </div>
      <div v-else>
        <b-link :to="{ name: 'users'}">
          User List
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BSkeletonImg, BLink,
} from 'bootstrap-vue'

export default {
  name: 'UserView',
  components: {
    BSkeletonImg,
    BLink,
  },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    user() {
      return this.$store.state[this.MODULE_NAME].user
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$emit('updateMenu', 'user-list')
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(() => {
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  setup() {
    const menuItems = ref()
    const MODULE_NAME = 'users'
    return {
      menuItems,
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
